import { useEffect, useState } from "react";
import { Box, Button, Container, Grid, Typography, TextField, FormControl, Select, MenuItem } from "@mui/material";
import Layout from "../../layout";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import useAuth from "../../hooks/useAuth";
import chart from "../../assets/images/chart.svg";
import chartUpdate from "../../assets/images/chartUpdate.svg";
import stockChart from "../../assets/images/stockChart.svg";
import { CallStyle } from "../call/styled";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import moment from "moment";
import { useFormik } from "formik";
import { UpdateCallValidationSchema } from "../../utils/schema";

const CallIdDetail = () => {
	const [updateCall] = useState(false);
	const {
		getCallDetails,
		handleApply,
		callDetails,
		getTradeTypeAfterRegister,
		tradeTypeAfterRegister,
		getStockNameOptions,
		stockNameOptions,
	} = useAuth();
	const { callId } = useParams();

	useEffect(() => {
		getTradeTypeAfterRegister();
		getStockNameOptions();
		getCallDetails(callId);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [callId]);

	useEffect(() => {
		const initialFormValues: any = {
			stockExchange: callDetails?.stockExchange,
			tradeType: callDetails?.tradeType,
			tradeCompany: callDetails.tradeCompany,
			callExpiredTime: callDetails.callExpiredTime,
			tradeDetails: callDetails.tradeDetails,
			target1: callDetails.target1,
			target2: callDetails.target2,
			target3: callDetails.target3,
			stopLoss: callDetails.stopLoss,
		}
		formik.setValues(initialFormValues);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [callDetails])

	const formik = useFormik({
		initialValues: {
			stockExchange: '',
			tradeType: '',
			tradeCompany: '',
			callExpiredTime: '',
			tradeDetails: '',
			target1: '',
			target2: '',
			target3: '',
			stopLoss: '',
		},
		validationSchema: UpdateCallValidationSchema,
		onSubmit: (values) => {
			handleApply({ ...values, id: callDetails?.id, callerId: callDetails.callerId });
		},
	});

	return (
		<Layout>
			<CallStyle style={{ backgroundColor: !updateCall ? "#F0F6F9" : "#fff" }}>
				<img src={!updateCall ? chart : chartUpdate} alt="chart" className="chartImg" />
				<Container className="callContent">
					<Grid container py={{ xs: 7, md: 9 }} spacing={!updateCall ? 3 : 5}>
						<Grid item xs={12} md={8} lg={9.2} xl={9.6}>
							<Box>
								<form onSubmit={formik.handleSubmit}>
									<Grid container spacing={{ xs: 4, lg: 6 }}>
										<Grid item xs={12} sm={6}>
											<Typography color={"#191D32"} mb={0.8}>
												Stock exchange
											</Typography>
											<FormControl fullWidth>
												<Select
													name="stockExchange"
													defaultValue={"Select "}
													displayEmpty
													inputProps={{ "aria-label": "Without label" }}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values.stockExchange}
													error={!!(formik.touched?.stockExchange && formik.errors.stockExchange)}

												>
													{stockNameOptions.map((item: string, index) => (
														<MenuItem value={item} key={index}>
															{item}
														</MenuItem>
													))}
												</Select>
											</FormControl>
										</Grid>
										<Grid item xs={12} sm={6}>
											<Typography color={"#191D32"} mb={0.8}>
												Trade type
											</Typography>
											<FormControl fullWidth>
												<Select
													name="tradeType"
													inputProps={{ "aria-label": "Without label" }}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values.tradeType}
													error={!!(formik.touched.tradeType && formik.errors.tradeType)}
												>
													{tradeTypeAfterRegister.map((item: any, index) => (
														<MenuItem value={item} key={index}>
															{item}
														</MenuItem>
													))}
												</Select>
											</FormControl>
										</Grid>
										<Grid item xs={12} sm={6}>
											<Typography color={"#191D32"} mb={0.8}>
												Trade company
											</Typography>
											<FormControl fullWidth>
												<TextField
													fullWidth
													name="tradeCompany"
													id="outlined-basic"
													variant="outlined"
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values?.tradeCompany}
													error={!!(formik.touched.tradeCompany && formik.errors.tradeCompany)}
													helperText={formik.touched.tradeCompany && formik.errors.tradeCompany as any}
												/>
											</FormControl>
										</Grid>
										<Grid item xs={12} sm={6}>
											<Typography color={"#191D32"} mb={0.8}>
												Expiry date
											</Typography>
											<Box className="dateField">
												<LocalizationProvider dateAdapter={AdapterDayjs} name="callExpiredTime">
													<DemoContainer components={["DatePicker", "DatePicker"]}>
														<DatePicker
															value={dayjs(moment(formik.values?.callExpiredTime).format("YYYY-MM-DD"))}
															onChange={(e: any) => {
																const isoDate = new Date(e).toISOString();
																formik.setFieldValue("callExpiredTime", isoDate.slice(0, 19) + "+0530");
																formik.setFieldValue("callExpireTime", moment(e).format("YYYY-MM-DD HH:mm:ss"));
															}}
														/>
													</DemoContainer>
												</LocalizationProvider>
											</Box>
										</Grid>
										<Grid item xs={12} sm={6}>
											<Typography color={"#191D32"} mb={0.8}>
												Trade details
											</Typography>
											<FormControl fullWidth>
												<TextField
													fullWidth
													id="outlined-basic"
													variant="outlined"
													name="tradeDetails"
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values.tradeDetails}
													error={!!(formik.touched.tradeDetails && formik.errors.tradeDetails)}
													helperText={formik.touched.tradeDetails && formik.errors.tradeDetails as any}
												/>
											</FormControl>
										</Grid>
										<Grid item xs={12} sm={6}>
											<Typography color={"#191D32"} mb={0.8}>
												Target 1
											</Typography>
											<FormControl fullWidth>
												<TextField
													fullWidth
													name="target1"
													id="outlined-basic"
													variant="outlined"
													placeholder="Enter target 1"
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values.target1}
													error={!!(formik.touched.target1 && formik.errors.target1)}
													helperText={formik.touched.target1 && formik.errors.target1 as any}
												/>
											</FormControl>
										</Grid>
										<Grid item xs={12} sm={6}>
											<Typography color={"#191D32"} mb={0.8}>
												Target 2
											</Typography>
											<FormControl fullWidth>
												<TextField
													fullWidth
													name="target2"
													id="outlined-basic"
													variant="outlined"
													placeholder="Enter target 2"
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values.target2}
													error={!!(formik.touched.target2 && formik.errors.target2)}
													helperText={formik.touched.target2 && formik.errors.target2 as any}
												/>
											</FormControl>
										</Grid>
										<Grid item xs={12} sm={6}>
											<Typography color={"#191D32"} mb={0.8}>
												Target 3
											</Typography>
											<FormControl fullWidth>
												<TextField
													fullWidth
													name="target3"
													id="outlined-basic"
													variant="outlined"
													placeholder="Enter target 3"
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values.target3}
													error={!!(formik.touched.target3 && formik.errors.target3)}
													helperText={formik.touched.target3 && formik.errors.target3 as any}
												/>
											</FormControl>
										</Grid>
										<Grid item xs={12} sm={6}></Grid>
										<Grid item xs={12} sm={6}>
											<Typography color={"#191D32"} mb={0.8}>
												Stop loss
											</Typography>
											<FormControl fullWidth>
												<TextField
													fullWidth
													name="stopLoss"
													id="outlined-basic"
													variant="outlined"
													placeholder="Enter stop loss"
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values.stopLoss}
													error={!!(formik.touched.stopLoss && formik.errors.stopLoss)}
													helperText={formik.touched.stopLoss && formik.errors.stopLoss as any}
												/>
											</FormControl>
										</Grid>
									</Grid>
									<Button type="submit"
										variant="contained"
										fullWidth
										className="btnPrimary btnApply"
										sx={{ my: 6 }}
										disabled={!formik.isSubmitting ? (!formik.isValid || !formik.dirty) : true}>
										Apply
									</Button>

								</form>
							</Box>
						</Grid>
						<Grid item xs={12} md={4} lg={2.8} xl={2.4}>
							<Box className="stockBox" textAlign={"center"} py={5} px={7}>
								<img src={stockChart} alt="chart" />
								<Typography variant="h2" mt={4.5} mb={1.5}>
									Looking to invest in stocks?
								</Typography>
								<Typography variant="h5">
									Lorem Ipsum is simply dummy text of the printing and typesetting industry.
								</Typography>
							</Box>
						</Grid>
					</Grid>
				</Container>
			</CallStyle>
		</Layout >
	);
};

export default CallIdDetail;
