import React, { ChangeEvent, Dispatch, SetStateAction, useContext, useState } from "react";
import { AuthContext, AuthContextTypes, UserState, callsDataType } from "../context/AuthContext";
import axiosClient from "../utils/axiosClient";
import { useNavigate } from "react-router-dom";
import { CallDetailsType } from "../context/AuthContext";
import moment from "moment";
import { toast } from "react-toastify";

const useAuth = () => {
	const { userState, profileData, isLogin, handleModels, getProfileDashboardDetails, setAuthState, setHandleModels } =
		useContext<AuthContextTypes>(AuthContext);
	const [callDetails, setCallDetails] = useState<CallDetailsType | any>({
		amount: "",
		callExpireTime: "",
		callExpiredTime: "",
		callerCompanyName: "",
		callerId: "",
		callsUpdatedAt: "",
		callstatus: "",
		createdAt: "",
		hostName: "",
		id: "",
		stockExchange: "",
		stopLoss: "",
		target1: "",
		target2: "",
		target3: "",
		tradeCompany: "",
		tradeDetails: "",
		tradeType: "",
		updateCount: "",
		updatedAt: "",
		viewed: "",
		tradeSymbol: "",
		putCall: "",
		buySell: "",
	});

	const [createCallModelOpen, setCallCreateModelOpen] = useState<boolean>(false);
	const [tradeTypeInRegister, setTradeTypeInRegister] = useState<Array<string>>([]);
	const [tradeTypeAfterRegister, setTradeTypeAfterRegister] = useState<Array<String>>([]);
	const [stockNameOptions, setStockNameOptions] = useState<Array<string>>([])

	const navigate = useNavigate();

	const onNumberChangeForRegister = ({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => {
		setHandleModels({ ...handleModels, state: { ...handleModels?.state, mobile: value } });
	};

	const handleSendRegisterOtp = async (isResendOtp?: boolean) => {
		try {
			setAuthState((state) => ({ ...state, isLoading: true }));
			const { data } = await axiosClient.post<{ result: { success: true } }>(
				`/otp/send?telephone=${encodeURIComponent(`${handleModels.state.countryCode}${handleModels.state.mobile}`)}`,
			);
			if (!data.result.success) throw Error("Something is wrong to send OTP!");
			if (!isResendOtp) setHandleModels({ ...handleModels, for: "register" });

			setAuthState((state) => ({
				...state,
				isLoading: false,
				isLogin: false,
				isError: false,
				toast: true,
				message: "OTP sent successfully",
			}));
		} catch (error: any) {
			setAuthState((state) => ({
				...state,
				isLoading: false,
				isError: true,
				toast: true,
				message: error.message || "Something is wrong",
			}));
		}
	};

	const onResendOtp = () => {
		handleSendRegisterOtp(true);
	};

	const handleSendLoginOtp = async (
		setStep: Dispatch<SetStateAction<"otp-verify" | "mobile-verify">>,
		isResendOtp?: boolean,
	) => {
		try {
			setAuthState((state) => ({ ...state, isLoading: true }));
			const { data } = await axiosClient.post<{ result: { success: true } }>(
				`/otp/send?telephone=${encodeURIComponent(`${handleModels.state.countryCode}${handleModels.state.mobile}`)}`,
			);
			if (!data.result.success) throw Error("Something is wrong to send OTP!");
			if (!isResendOtp) setHandleModels({ ...handleModels, for: "login" });
			setStep("otp-verify");
			setAuthState((state) => ({
				...state,
				isLoading: false,
				isError: false,
				toast: true,
				message: "OTP sent successfully",
			}));
		} catch (error: any) {
			setAuthState((state) => ({
				...state,
				isLoading: false,
				isError: true,
				toast: true,
				message: error.message || "Something is wrong",
			}));
		}
	};

	const onResendLoginOtp = (setStep: Dispatch<SetStateAction<"otp-verify" | "mobile-verify">>) => {
		handleSendLoginOtp(setStep, true);
	};

	const handleFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const file = e.target.files?.[0];
		if (file) {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => {
				setHandleModels((ps) => ({ ...ps, state: { ...ps.state, profile: reader.result as string } }));
			};
		}
	};

	const handleLogin = async (
		setStep: Dispatch<SetStateAction<"otp-verify" | "mobile-verify">>,
		onClose?: () => void,
	) => {
		try {
			setAuthState((authState) => ({ ...authState, isLoading: true }));
			const { data } = await axiosClient.post<{ result: UserState }>(
				`/caller/login?telephone=${encodeURIComponent(
					`${handleModels.state.countryCode}${handleModels.state.mobile}`,
				)}&otp=${handleModels.state.otp}&deviceToken=deviceToken&devices=devices`,
			);
			if (!data.result) {
				if (onClose) onClose();
				throw Error("User Not found");
			}
			localStorage.setItem("user", JSON.stringify(data.result));
			getProfileDashboardDetails();
			setAuthState((authState) => ({
				...authState,
				isLoading: false,
				toast: true,
				message: "User login successfully.",
				isLogin: true,
			}));
			setStep("mobile-verify");
			setHandleModels({ ...handleModels, for: null, state: { ...handleModels?.state, otp: "" } });
		} catch (error: any) {
			setAuthState((authState) => ({
				...authState,
				isError: true,
				toast: true,
				message: error?.response?.data?.message ?? error.message,
			}));
		}
	};

	const handleRegister = async (
		setStep: Dispatch<SetStateAction<"verification" | "upload-profile" | "business-info" | "approval-request">>,
	) => {
		try {
			setAuthState((authState) => ({ ...authState, isLoading: true }));
			const { data } = await axiosClient.post<{ result: UserState }>(
				`/caller/register?otp=${handleModels.state.otp}&deviceToken=deviceToken&devices=devices`,
				{
					businessName: handleModels.state.businessName,
					sebiNumber: handleModels.state.sebiNumber,
					tradeType: handleModels.state.tradeType?.split(","),
					profileImage: handleModels.state.profile,
					telephone: `${handleModels.state.countryCode}${handleModels.state.mobile}`,
				},
			);
			localStorage.setItem("user", JSON.stringify(data.result));
			getProfileDashboardDetails();
			setAuthState((authState) => ({
				...authState,
				isLoading: false,
				toast: true,
				message: "User register successfully.",
				isLogin: true,
			}));
			setHandleModels({ ...handleModels, for: null });
		} catch (error: any) {
			setAuthState((authState) => ({
				...authState,
				isError: true,
				toast: true,
				message: error?.response?.data?.message || error.message || "Something is wrong",
			}));
		}
	};

	const handleLogout = async () => {
		try {
			setAuthState((authState) => ({ ...authState, isLoading: true }));
			const res = await axiosClient.post(`/caller/logout`);
			if (res.status === 200) {
				localStorage.removeItem("user");
				setHandleModels({ ...handleModels, for: null, state: { ...handleModels?.state, mobile: "" } });
				setAuthState((authState) => ({
					...authState,
					isLoading: false,
					toast: true,
					message: "User logout successfully.",
					isLogin: false,
				}));
				navigate("/");
				// setHandleModels({ ...handleModels, for: null });
			}
		} catch (error: any) {
			setAuthState((authState) => ({
				...authState,
				isError: true,
				toast: true,
				message: error?.response?.data?.message ?? error.message,
			}));
		}
	};

	const getCalls = async (start: any) => {
		setAuthState((authState) => ({ ...authState, isLoading: true }));
		try {
			const { data } = await axiosClient.get<{ result: callsDataType }>(`/caller/myCalls?start=${start}`);
			setAuthState((authState) => ({
				...authState,
				callsData: {
					mycalls: data?.result?.mycalls,
					totalPages: data?.result?.totalPages,
				},
			}));
			return data;
		} catch (error) {
			console.log(error);
		} finally {
			setAuthState((authState) => ({ ...authState, isLoading: false }));
		}
	};

	const getCallDetails = async (id: any) => {
		try {
			const { data } = await axiosClient.get(`/caller/callDetails?callId=${id}`);
			if (data) setCallDetails(data?.result);
		} catch { }
	};

	const handleApply = async (value: any = {}) => {
		try {
			if (value?.callExpiredTime) {
				const isoDate = new Date(value?.callExpiredTime).toISOString();
				value = {
					...value,
					...(value?.callExpiredTime
						? { callExpireTime: moment(value?.callExpiredTime).format("YYYY-MM-DD HH:mm:ss") }
						: {}),
					...(value?.callExpiredTime ? { callExpiredTime: isoDate.slice(0, 19) + "+0530" } : {}),
				};
			}
			await axiosClient.post(`/caller/call`, value);
			toast.success("Call details updated successfully.");
		} catch (error: any) {
			toast.error(error.message || "Something is wrong");
		}
	};

	const handleCreateCall = async (onClose: any, value: any) => {
		try {
			const { status } = await axiosClient.post("/caller/call", value);
			if (status === 200) {
				setAuthState((authState) => ({
					...authState,
					isLoading: false,
					toast: true,
					message: "Call create successfully.",
					isLogin: true,
				}));
				onClose();
			}
		} catch (error: any) {
			setAuthState((state) => ({
				...state,
				isLoading: false,
				isError: true,
				toast: true,
				message: error.response?.data?.message || error?.message || "Something is wrong",
			}));
		}
	};

	const getTradeTypeInRegister = async () => {
		const { data } = await axiosClient.get("dropdown/tradetype");
		setTradeTypeInRegister(data?.result);
	};

	const getTradeTypeAfterRegister = async () => {
		const { data } = await axiosClient.get("/dropdown/tradetypeAfterRegister");
		setTradeTypeAfterRegister(data?.result);
	};

	const getStockNameOptions = async () => {
		const { data } = await axiosClient.get('/dropdown/stockNameOptions');
		setStockNameOptions(data?.result)
	}

	return {
		...userState,
		isLogin,
		handleModels,
		callDetails,
		profileData,
		createCallModelOpen,
		tradeTypeInRegister,
		tradeTypeAfterRegister,
		stockNameOptions,
		onResendOtp,
		onResendLoginOtp,
		handleLogin,
		handleRegister,
		handleLogout,
		setHandleModels,
		handleFileInputChange,
		handleSendLoginOtp,
		handleSendRegisterOtp,
		onNumberChangeForRegister,
		getProfileDashboardDetails,
		getCalls,
		getCallDetails,
		setCallDetails,
		handleApply,
		handleCreateCall,
		setCallCreateModelOpen,
		getTradeTypeInRegister,
		getTradeTypeAfterRegister,
		getStockNameOptions
	};
};

export default useAuth;
