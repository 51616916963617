import { Box, Button, Typography } from "@mui/material";
import moment from "moment";
import backArrow from "../../../assets/images/callBackArrow.svg";
import { CallModelStyle } from "./styled";

import { CallDetailsType } from "../../../context/AuthContext";
import { useNavigate } from "react-router-dom";

type Props = {
	open: boolean;
	onClose: () => void;
	callDetails: CallDetailsType;
};

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	bgcolor: "background.paper",
	border: 0,
	width: 606,
	borderRadius: "12px",
	boxShadow: 24,
	padding: "15px 15px 56px 15px",
	textAlign: "center",
	overflow: "auto",
	maxHeight: "90vh",
	"@media(max-width: 680px)": {
		width: "88%",
		padding: "15px 10px 30px 10px",
	},
};

const ViewCall: React.FC<Props> = ({ open, onClose, callDetails }) => {
	const navigate = useNavigate();

	return (
		<CallModelStyle
			open={open}
			onClose={onClose}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
			className="call-modal"
		>
			<Box sx={style}>
				<Box className="btnClose" textAlign={"left"}>
					<img src={backArrow} alt="back" onClick={onClose} />
				</Box>
				<Typography variant="h1" mb={4}>
					{callDetails?.tradeCompany}
				</Typography>
				<Box className="viewCallDesc" width={"85%"} mx={"auto"}>
					<Box textAlign={"center"} bgcolor={"#f6f6fc"} p={2}>
						<Typography variant="h5">{callDetails?.tradeType}</Typography>
					</Box>

					<Box className="stock-result" p={2} display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
						<Typography variant="h6">Stock exchange</Typography>
						<Typography textAlign={"right"}>{callDetails?.stockExchange}</Typography>
					</Box>

					<Box className="stock-result" p={2} display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
						<Typography variant="h6">Expiry date</Typography>
						<Typography textAlign={"right"}>{moment(callDetails?.callExpiredTime).format("DD MMM , YYYY")}</Typography>
					</Box>
					<Box className="stock-result" p={2} display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
						<Typography variant="h6">Buy Sell Amount</Typography>
						{/* <Typography textAlign={"right"}>{callDetails.}</Typography> */}
					</Box>
					<Box className="stock-result" p={2} display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
						<Typography variant="h6">Stop loss</Typography>
						<Typography textAlign={"right"}>{callDetails?.stopLoss}</Typography>
					</Box>
					<Box className="stock-result" p={2} display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
						<Typography variant="h6">Target 1</Typography>
						<Typography textAlign={"right"}>{callDetails?.target1}</Typography>
					</Box>
					<Box className="stock-result" p={2} display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
						<Typography variant="h6">Target 2</Typography>
						<Typography textAlign={"right"}>{callDetails?.target2}</Typography>
					</Box>
					<Box className="stock-result" p={2} display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
						<Typography variant="h6">Target 3</Typography>
						<Typography textAlign={"right"}>{callDetails?.target3}</Typography>
					</Box>
				</Box>
				<Button
					variant="contained"
					className="btnPrimary btnUpdate"
					sx={{ width: "85%", mt: 2.5 }}
					onClick={() => {
						navigate(`/call/${callDetails?.id}`);
						onClose();
					}}
				>
					Update call
				</Button>
			</Box>
		</CallModelStyle>
	);
};

export default ViewCall;
