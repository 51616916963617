import React, { useEffect } from "react";
import { Box, Button, TextField, Typography, Select, FormControl, MenuItem, Grid } from "@mui/material";
import backArrow from "../../../assets/images/callBackArrow.svg";
import { CallModelStyle } from "./styled";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import useAuth from "../../../hooks/useAuth";
import { useFormik } from "formik";
import { crateCallValidationSchema } from "../../../utils/schema";
import moment from "moment";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	bgcolor: "background.paper",
	border: 0,
	width: 1000,
	borderRadius: "12px",
	boxShadow: 24,
	padding: "15px 15px 56px 15px",
	textAlign: "center",
	overflow: "auto",
	maxHeight: "80vh",
	"@media(max-width: 1080px)": {
		width: "88%",
		padding: "15px 10px 30px 10px",
	},
};

type Props = {
	open: boolean;
	onClose: () => void;
};

const CreateCall: React.FC<Props> = ({ open, onClose }) => {
	const { caller, handleCreateCall, getTradeTypeAfterRegister, tradeTypeAfterRegister } = useAuth();

	const formik = useFormik({
		initialValues: {
			id: 0,
			amount: 0,
			callerId: caller ? caller.id : null,
			stockExchange: "",
			tradeType: "",
			callerCompanyName: "TATA",
			tradeCompany: "",
			callExpiredTime: null,
			tradeDetails: "",
			target1: 0,
			target2: 0,
			target3: 0,
			stopLoss: 0,
			callExpireTime: null,
			viewed: true
		},
		validationSchema: crateCallValidationSchema,
		onSubmit: (values) => {
			console.log("value===>", values)
			handleCreateCall(onClose, values);
			formik.resetForm();
		},
	});

	useEffect(() => {
		getTradeTypeAfterRegister();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);


	return (
		<CallModelStyle open={open} onClose={onClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" className="call-modal">
			<form onSubmit={formik.handleSubmit}>
				<Box sx={style}>
					<Box className="btnClose" textAlign={"left"}>
						<img src={backArrow} alt="back" onClick={onClose} />
					</Box>
					<Typography variant="h1" mb={4}>
						Create Call
					</Typography>

					<Box className="viewCallDesc create-call-data" width={"85%"} mx={"auto"} textAlign={"left"}>
						<Grid container spacing={{ xs: 3, md: 4 }} py={4}>
							<Grid item xs={12} sm={6}>
								<Typography color={"#191D32"} mb={0.8}>
									Stock exchange
								</Typography>
								<FormControl fullWidth>
									<Select
										name="stockExchange"
										displayEmpty
										inputProps={{ "aria-label": "Without label" }}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										value={formik.values.stockExchange}
										error={!!(formik.touched.stockExchange && formik.errors.stockExchange)}
									>
										<MenuItem value="BSC">BSC</MenuItem>
										<MenuItem value="NSE">NSE</MenuItem>
									</Select>
									<Typography className="err-mess">
										{formik.touched.stockExchange && formik.errors.stockExchange}
									</Typography>
								</FormControl>
							</Grid>

							<Grid item xs={12} sm={6}>
								<Typography color={"#191D32"} mb={0.8}>
									{" "}
									Trade type
								</Typography>
								<FormControl fullWidth>
									<Select
										name="tradeType"
										displayEmpty
										inputProps={{ "aria-label": "Without label" }}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										value={formik.values.tradeType}
										error={!!(formik.touched.tradeType && formik.errors.tradeType)}
									>
										{tradeTypeAfterRegister.map((item: any, index) => (
											<MenuItem value={item} key={index}>
												{item}
											</MenuItem>
										))}
									</Select>
									<Typography className="err-mess">{formik.touched.tradeType && formik.errors.tradeType}</Typography>
								</FormControl>
							</Grid>
							<Grid item xs={12} sm={6}>
								<Typography color={"#191D32"} mb={0.8}>
									Trade company
								</Typography>
								<TextField
									fullWidth
									name="tradeCompany"
									id="outlined-basic"
									variant="outlined"
									placeholder="Enter Trade company"
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									value={formik.values.tradeCompany}
									error={!!(formik.touched.tradeCompany && formik.errors.tradeCompany)}
									helperText={formik.touched.tradeCompany && formik.errors.tradeCompany}
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<Typography color={"#191D32"} mb={0.8}>
									Expiry date
								</Typography>
								<Box className="dateField">
									<LocalizationProvider dateAdapter={AdapterDayjs} name="callExpiredTime">
										<DemoContainer components={["DatePicker"]}>
											<DatePicker
												onChange={(e: any) => {
													const isoDate = new Date(e).toISOString();
													formik.setFieldValue("callExpiredTime", isoDate.slice(0, 19) + "+0530");
													formik.setFieldValue("callExpireTime", moment(e).format("YYYY-MM-DD HH:mm:ss"));
												}}
											/>
										</DemoContainer>
									</LocalizationProvider>
								</Box>
								<Typography className="err-mess">
									{formik.touched.callExpiredTime && formik.errors.callExpiredTime}
								</Typography>
							</Grid>
							<Grid item xs={12} sm={6}>
								<Typography color={"#191D32"} mb={0.8}>
									Trade details
								</Typography>
								<FormControl fullWidth>
									<TextField
										fullWidth
										id="outlined-basic"
										variant="outlined"
										name="tradeDetails"
										placeholder="Enter Trade details"
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										value={formik.values.tradeDetails}
										error={!!(formik.touched.tradeDetails && formik.errors.tradeDetails)}
										helperText={formik.touched.tradeDetails && formik.errors.tradeDetails}
									/>
								</FormControl>
							</Grid>

							<Grid item xs={12} sm={6}>
								<Typography color={"#191D32"} mb={0.8}>
									Target 1
								</Typography>
								<TextField
									fullWidth
									name="target1"
									id="outlined-basic"
									variant="outlined"
									placeholder="Enter target 1"
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									value={formik.values.target1}
									error={!!(formik.touched.target1 && formik.errors.target1)}
									helperText={formik.touched.target1 && formik.errors.target1}
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<Typography color={"#191D32"} mb={0.8}>
									Target 2
								</Typography>
								<TextField
									fullWidth
									name="target2"
									id="outlined-basic"
									variant="outlined"
									placeholder="Enter target 2"
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									value={formik.values.target2}
									error={!!(formik.touched.target2 && formik.errors.target2)}
									helperText={formik.touched.target2 && formik.errors.target2}
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<Typography color={"#191D32"} mb={0.8}>
									Target 3
								</Typography>
								<FormControl fullWidth>
									<TextField
										name="target3"
										id="outlined-basic"
										variant="outlined"
										placeholder="Enter target 3"
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										value={formik.values.target3}
										error={!!(formik.touched.target3 && formik.errors.target3)}
										helperText={formik.touched.target3 && formik.errors.target3}
									/>
								</FormControl>
							</Grid>
							<Grid item xs={12} sm={6}>
								<Typography color={"#191D32"} mb={0.8}>
									Stop loss
								</Typography>
								<FormControl fullWidth>
									<TextField
										name="stopLoss"
										id="outlined-basic"
										variant="outlined"
										placeholder="Enter stop loss"
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										value={formik.values.stopLoss}
										error={!!(formik.touched.stopLoss && formik.errors.stopLoss)}
										helperText={formik.touched.stopLoss && formik.errors.stopLoss}
									/>
								</FormControl>
							</Grid>
						</Grid>
					</Box>
					<Button
						type="submit"
						variant="contained"
						className="btnPrimary btnUpdate"
						sx={{ width: "85%", mt: 2.5 }}
						disabled={!formik.isSubmitting ? (!formik.isValid || !formik.dirty) : true}
					>
						Create call
					</Button>
				</Box>
			</form>
		</CallModelStyle >
	);
};

export default CreateCall;
