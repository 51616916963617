import { useEffect } from "react";
import useAuth from "../../hooks/useAuth";
import { Link } from "react-router-dom";

import { Box, Container, Grid, Typography } from "@mui/material";
import { StarRounded } from "@mui/icons-material";
import Layout from "../../layout";
import CreateCall from "../../components/models/call/CreateCall";

import income from "../../assets/images/income.svg";
import coin from "../../assets/images/coin.svg";
import { ProfileStyle } from "./styled";

const Profile = () => {
	const {
		profileData,
		handleModels,
		createCallModelOpen,
		setHandleModels,
		getProfileDashboardDetails,
		setCallCreateModelOpen,
	} = useAuth();

	useEffect(() => {
		getProfileDashboardDetails();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (!profileData?.active) {
			setHandleModels({ ...handleModels, for: "register" });
		} else {
			setHandleModels({ ...handleModels, for: null });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [profileData?.active]);

	return (
		<Layout>
			<ProfileStyle>
				<Container className="profileContent">
					<Grid container pt={5}>
						<Grid item xs={12} md={7} lg={6} order={{ xs: 2, md: 1 }} mt={{ xs: 3, md: 0 }}>
							<Typography>Hello,</Typography>
							<Typography variant="h2" mt={1.5} display={"flex"} alignItems={"center"}>
								{" "}
								{profileData?.name} <StarRounded />
								<span>{profileData.rating}</span>
							</Typography>

							<Typography variant="h6" mt={5.5} mb={0.5}>
								Outstanding amount
							</Typography>
							<Typography variant="h3" display={"flex"}>
								<span>₹</span> {profileData?.outstandingAmount}
							</Typography>
							<Grid container spacing={3} mt={1} mb={4}>
								<Grid item xs={12} sm={6}>
									<Box className="incomeBox" display={"flex"} alignItems={"center"}>
										<img src={income} alt="income" />
										<Box ml={{ xs: 3, md: 1, lg: 3 }}>
											<Typography>Today's calls</Typography>
											<Typography variant="h4" display={"flex"} alignItems={"center"}>
												{profileData?.todayCalls}
											</Typography>
										</Box>
									</Box>
								</Grid>

								<Grid item xs={12} sm={6}>
									<Box className="incomeBox" display={"flex"} alignItems={"center"}>
										<img src={coin} alt="coin" />
										<Box ml={{ xs: 3, md: 1, lg: 3 }}>
											<Typography>Today's earnings</Typography>
											<Typography variant="h4" display={"flex"} alignItems={"center"}>
												{profileData?.todayEarnings}
												<span>₹</span>
											</Typography>
										</Box>
									</Box>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={12} md={5} lg={6} textAlign={{ xs: "center", md: "right" }} order={{ xs: 1, md: 2 }}>
							<img
								src={profileData?.profileImage}
								className="profileGrpImg"
								width={"287px"}
								height={"287px"}
								alt="profile"
							/>
						</Grid>
					</Grid>

					<Grid container spacing={2} pb={8}>
						<Grid item xs={12} sm={6}>
							<Box className="amoutBox" display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
								<Typography variant="h6">Total number of calls given</Typography>
								<Typography variant="h6" ml={2}>
									{profileData?.todayCalls}
								</Typography>
							</Box>
						</Grid>

						<Grid item xs={12} sm={6}>
							<Box className="amoutBox" display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
								<Typography variant="h6">Total number of subscribers</Typography>
								<Typography variant="h6" ml={2}>
									{profileData?.totalSubscribers}
								</Typography>
							</Box>
						</Grid>

						<Grid item xs={12} sm={6}>
							<Box className="amoutBox" display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
								<Typography variant="h6">Number of calls given today</Typography>
								<Typography variant="h6" ml={2}>
									{profileData?.todayCalls}
								</Typography>
							</Box>
						</Grid>

						<Grid item xs={12} sm={6}>
							<Box className="amoutBox" display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
								<Typography variant="h6">Number of subscribers today</Typography>
								<Typography variant="h6" ml={2}>
									{profileData?.todaySubscribers}
								</Typography>
							</Box>
						</Grid>

						<Grid item xs={12} sm={6}>
							<Link to={""} style={{ textDecoration: "none" }} onClick={() => setCallCreateModelOpen(true)}>
								<Box className="amoutBox callBox" justifyContent={"space-between"}>
									<Typography variant="h6">Calls</Typography>
								</Box>
							</Link>
						</Grid>

						<Grid item xs={12} sm={6}>
							<Link to={"/call"} style={{ textDecoration: "none" }}>
								<Box className="amoutBox callBox" display={"flex"} justifyContent={"space-between"}>
									<Typography variant="h6">My Calls</Typography>
									<Typography variant="h6" ml={2}>
										{profileData?.todayCalls}
									</Typography>
								</Box>
							</Link>
						</Grid>
					</Grid>
				</Container>
			</ProfileStyle>

			<CreateCall open={createCallModelOpen} onClose={() => setCallCreateModelOpen(false)} />
		</Layout>
	);
};

export default Profile;
