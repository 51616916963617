import { Box, Typography, Button } from "@mui/material";
import whatsapp from "../../../assets/images/whatsapp.svg";
import useAuth from "../../../hooks/useAuth";

const ApprovalRequest = () => {
	const { handleLogout } = useAuth();
	return (
		<>
			<Typography variant="h3" mb={1}>
				Request for Approval
			</Typography>
			<Typography mb={6}>Check the below information carefully</Typography>
			<Box textAlign={"left"} px={2.5}>
				<Box justifyContent={"center"} display={"flex"} mb={2.5}>
					<Button variant="contained" className="btnPrimary" onClick={handleLogout}>
						Logout
					</Button>
				</Box>

				<Typography variant="h4" mb={1.5} fontSize={"20px"}>
					Description
				</Typography>
				<Typography>Share your PAN Card and upi id to below whatsapp number and wait for the confirmation.</Typography>
				<Typography variant="h4" mb={1.5} mt={9}>
					Whatsapp number
				</Typography>
				<Box display={"flex"} alignItems={"center"}>
					<img src={whatsapp} alt="whatsapp" />
					<Typography variant="h5" ml={2}>
						{" "}
						+91 5865 418 475
					</Typography>
				</Box>
			</Box>
		</>
	);
};

export default ApprovalRequest;
