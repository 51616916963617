/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import Layout from "../../layout";
import ViewCall from "../../components/models/call/viewCall";
import ReactPaginate from "react-paginate";
import useAuth from "../../hooks/useAuth";
import { AuthContext, callsDataType } from "../../context/AuthContext";
import chart from "../../assets/images/chart.svg";
import chartUpdate from "../../assets/images/chartUpdate.svg";
import stockChart from "../../assets/images/stockChart.svg";
import info from "../../assets/images/info.svg";
import { CallStyle } from "./styled";
import moment from "moment";
import "../call/paginatestyle.css";

const Call = () => {
	const { getCalls, getCallDetails, callDetails } = useAuth();
	const { callsData, isLoading } = useContext(AuthContext);
	const [updateCall] = useState(false);
	const [start, setStart] = useState(0);
	const [open, setOpen] = useState(false);
	const [currentPage, setCurrentPage] = useState(0);
	const totalPages = Math.ceil(callsData.totalPages / callsData.mycalls.length);

	const handlePageChange = (event: any) => {
		setCurrentPage(event.selected);
		setStart(event.selected * 5);
	};

	useEffect(() => {
		getCalls(start);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [start]);

	return (
		<Layout>
			<CallStyle style={{ backgroundColor: !updateCall ? "#F0F6F9" : "#fff" }}>
				<img src={!updateCall ? chart : chartUpdate} alt="chart" className="chartImg" />
				<Container className="callContent">
					<Grid container py={{ xs: 7, md: 9 }} spacing={!updateCall ? 3 : 5}>
						<Grid item xs={12} md={8} lg={9.2} xl={9.6}>
							<Box pb={{ xs: 4, md: 6 }}>
								<Box mb={2} display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
									<Typography variant="h3" className="textPrimary">
										My Call
									</Typography>
									{/* <Button
											className="textSecondary"
											onClick={() => {
												setOpen(true);
												setCallType("My Call");
											}}
										>
											See All
										</Button> */}
								</Box>
								<Grid container spacing={3.5}>
									{callsData?.mycalls.map((data: any, key: number) => (
										<Grid item key={key} xs={12} sm={6} lg={3}>
											<Box className="googleInc">
												<Box display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
													<Typography variant="h4">{data?.callerCompanyName}</Typography>
													<img src={info} alt="info" />
												</Box>
												<Typography mt={0.5} mb={5}>
													by {data?.tradeCompany}
												</Typography>
												<Button
													className="textPrimary"
													onClick={() => {
														getCallDetails(data?.id);
														setOpen(true);
													}}
												>
													View call
												</Button>

												<Typography mt={0.5}>{moment(data?.callExpiredTime).format("MMM DD,  HH:mm A")}</Typography>
											</Box>
										</Grid>
									))}
								</Grid>
							</Box>
							<div id="react-paginate">
								<ReactPaginate
									pageCount={totalPages}
									onPageChange={handlePageChange}
									pageRangeDisplayed={5}
									marginPagesDisplayed={0}
									forcePage={currentPage}
									containerClassName={"pagination"}
									previousLabel={"<"}
									nextLabel={">"}
									activeClassName={"active"}
								/>
							</div>
						</Grid>

						<Grid item xs={12} md={4} lg={2.8} xl={2.4}>
							<Box className="stockBox" textAlign={"center"} py={5} px={7}>
								<img src={stockChart} alt="chart" />
								<Typography variant="h2" mt={4.5} mb={1.5}>
									Looking to invest in stocks?
								</Typography>
								<Typography variant="h5">
									Lorem Ipsum is simply dummy text of the printing and typesetting industry.
								</Typography>
							</Box>
						</Grid>
					</Grid>
				</Container>
				{open && <ViewCall open={open} onClose={() => setOpen(false)} callDetails={callDetails} />}
				{/* <ViewAll onClose={() => setOpen(false)} callType={callType} allData={callsData} /> */}
			</CallStyle>
		</Layout>
	);
};

export default Call;
