import axios from "axios";

const axiosClient = axios.create({
	baseURL: "http://tradingappdev-env.eba-3hygwjy2.ap-south-1.elasticbeanstalk.com/v1",
	headers: {
		Accept: "application/json",
		"X-server-appid": "server",
		"X-server-appkey": "server",
	},
});

axiosClient.interceptors.request.use((config) => {
	const { sessionToken = "" } = JSON.parse(localStorage.getItem("user") ?? "{}");
	if (sessionToken) config.headers["X-server-session-token"] = sessionToken;
	return config;
});

export default axiosClient;
